<template>
  <account-setting-general />
</template>

<script>
import AccountSettingGeneral from './AccountSettingGeneral.vue'

export default {
  components: {
    AccountSettingGeneral,
  },
}
</script>
